.modal-container {
    display: none;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    background-color: rgb(0, 0, 0, .3);
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 30px;
    border-bottom: 1px solid #ebebeb;
    background-color: #f7f7f7;
}

.modal-header h5 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    margin: auto;
    margin-inline: 45em;
    background-color: white;
    border-radius: .5em;
}

.modal-body {
    width: 100%;
    padding: 27px 30px;
}

.modal-form input,
.modal-form select {
    background-color: #f7f7f7 !important;
}

.modal-form input {
    padding-block: 11px !important;
}

.modal-form select {
    padding-block: 15px !important;
}

.show {
    display: flex !important;
}

.rmsc .dropdown-container {
    background-color: #f7f7f7 !important;
    padding-block: 4px !important;
    border-radius: 0 !important;
    padding-left: 40px !important;
}

.rmsc .dropdown-content {
    padding-block: 4px !important;
    padding-left: 0 !important;
    margin-left: -40px !important;
    z-index: 111 !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
    display: inline-block;
}

.rmsc .dropdown-content .panel-content {
    overflow: hidden !important;
    border-radius: 0 !important;
    background-color: #f7f7f7 !important;
}

.rmsc .select-item * {
    margin-left: -80px !important;
}

.rmsc .select-item:hover {
    background: none !important;
}

.multiselect {
    position: relative;
}

.multiselect > i {
    position: absolute;
    z-index: 11;
    top: 15px;
    font-size: 1rem;
    margin-left: 1em;
}